:root{

  --border-radius:.25rem;
  --border-padding:.25rem;
  --border-margin:.25rem;
  /* --color-primary:#ff9900; */
  --color-primary:#DB9B00;
  --color-primary-2:#ffcd80;
  --color-primary-3:#ffe5bf;


  --color-screen:rgba(0, 0, 0, .7);

  --color-secondary:#423e50;
  --color-secondary-2:#605975;
  --color-secondary-3:#807796;    
  --color-secondary-4:#b0abbe;

  --color-mute:#444444;
  --color-mute-2:#aaaaaa;
  --color-mute-3:#f2f2f2;

--color1: #33aaff;
--color1-d1: #2d95df;
--color1-d2: #267fbf;
--color1-d3: #206a9f;
--color1-d4: #1a5580;
--color1-d5: #134060;
--color1-d6: #0d2b40;
--color1-d7: #061520;
--color2: #3344ff;
--color3: #8833ff;
--color4: #ee33ff;
--color5: #ff33aa;
--color6: #ff3344;

  --font-secondary: "Raleway", sans-serif;
  --font-primary: Arial, Helvetica, sans-serif;    
  
  --color-9:#fff5e6;

  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  color:#333333;

}

.form-horizontal .form-group{
  margin-left: 0;
  margin-right: 0;
}

/* .form-group{
  scroll-margin-top: -150px;
}

.form-group *:focus-visible{
  color:red;
  scroll-margin-top: 100px;
} */

.btn{
  font-size: 1em;
}

.flex{
  display: flex;
}

.full-page{
  position: fixed;
  left:0;
  right:0;
  top:0;
  bottom: 0;
}

.flex-middle{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}

.alert{
  margin-bottom: 0;
}
.grow {
  flex-grow: 1;
}
.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}
.larger{
  font-size: 1.4em;
}

.flex.center{
  align-items: center;
}

.flex-between{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.flex-between.no-wrap, .no-wrap{
  flex-wrap: nowrap;
}

.flex-centered{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.flex-evenly{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

input[type=color].form-control{
  padding: 0;
  border: none;
  -webkit-appearance: none;
}
input[type=checkbox].form-control{
  display: inline-block;
  width: inherit;
  margin-right: 1em;
  margin-left:.3em;
  vertical-align:bottom;
}

.text-centered{
text-align: center;
}


input[type=checkbox]{
  transform: scale(1.4); 
  padding-right: .8em;
  position: relative;
  margin-right: 1em;
}

input[type=file].form-control{
  padding: 3px;
}

label > input[type=checkbox]{
  margin-right:1em;
}
.click{
  cursor: pointer;
}

.template-item-bar{
  padding:.5em;
  border:solid 1px #aaa;
  border-radius: .2em;
  margin-bottom: 1em;
}

.main{
  position: relative;
}

.menu{
  position:fixed;
  left:0; right: 0; top:0;
  bottom:0;
  background-color: var(--color-mute-3);
  z-index: 101;
  padding:1em;
  border:1em solid var(--color-mute-2);
}

.menu-section-container{
  
}

.form-group label{
  font-weight: bolder;
}

.icon-btns i{
  margin:0 .3em;
  font-size: 1.3em;
  cursor: pointer;
}

.is-invalid label{
  text-decoration: underline double;
  color:#dc3545  
}

.is-invalid{
  background-color: rgba(220, 53, 69, .05);
  border-radius: .4em;
  padding:1em;
}

iframe{
  width: 100%;
  border:none;
}

.orkboo.container{
  background-color: var(--color-mute-3);
  min-height: 100vh;
  width:min(1140px, calc(100vw - 2rem));
  max-width:min(1140px, calc(100vw - 2rem));
  padding-left:0;
  padding-right: 0;
  position: relative;
  /* padding-bottom: 2em; */
  /* padding-top: .25em; */
  
}

@media (min-width: 768px ) and ( max-width: 992px){
.form-horizontal .control-label {
    text-align: left;
}
}

.config-form-landing-container {
  overflow: auto;
  max-height: calc(100vh - 400px);
  border:1px solid #aaaaaa;
  border-radius: 4px;
  padding:.5em;
}

.config-form-landing-container .config-form-item{
  border:1px solid #aaaaaa;
  border-radius: 4px;
  padding:.5em;
  margin: .2em .5em .2em 0;
}

.config-form-landing-container .config-form-item:hover{
  box-shadow: 3px 3px 5px #aaaaaa;
}

.form-control.form-control-between{
  width: calc(50% - 2em);
}

.form-horizontal .control-label {
  display: inline;
}

.headroom{
  margin-top: 1rem;
}

.btn.btn-default:hover{
  border-color:#bbbbbb;
}

.workbook-preview-container{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}

.workbook-preview-container .workbook-tile-button{
  height: 2.5rem;
}

.workbook-preview-container .workbook-tile-button button{
  
  font-weight: bolder;
  font-size: larger;
  position: absolute;
  bottom: .5rem;
  right: .5rem;
}
.workbook-preview-container .workbook-preview{
  /* cursor: pointer; */
  position: relative;
  /* width: max(max(250px, calc(33.33% - .66rem)), max(250px, calc(50% - .5rem))); */
  
  width: min(max(340px, calc(33.33% - .66rem)), max(calc(50% - 1rem), 340px));
  /* max-width: min(1140, calc(33.33% - .66rem)); */
  height: 250px;
  border-radius: .4rem;
  margin: .3rem;
  border: 1px solid #aaaaaa;
  padding: 1rem;
  display: flex;

  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
}

.workbook-preview-container .workbook-preview .wb-tile-edit{
  position: absolute;
  top:.3rem;
  right:.3rem;
}

.top-of-tile{
  position: absolute;
  top:0;
  right:0;
  left:0;
  text-align: center;
  background-color: rgba(0, 0, 0, .4);
}

.multi-select .cool__control {
  width: 100%;
}

.easy-file-image img.thumbnail-1 {
  max-width: 100px;
  max-height: 100px;
}
.thumbnail-1 img{
  max-width: 200px;
  max-height: 200px;
}

img.thumbnail-1{
  max-width: 200px;
  max-height: 200px;
}
.user-registration{
  margin:1rem;
  padding:1rem;
}
.user-desc{

}

ul.user-desc{
  list-style: none;  
  display: flex;
  justify-content: space-between;
}

.user-desc li{

}

.o-auth-btn-container .btn{
  /* width: 300px; */
  font-size: 2rem;
  
}

.o-auth-btn-container{
  margin-bottom: 1rem;
}

.sso-container{
  height: 100%;
  min-height: 150px;
  margin-right: 2rem;
  padding: 1rem;
  /* background-color: var(--color-secondary-3); */
  border-radius: .2rem;
  color: var(--color-secondary);
  font-size: 1.4em;
}

.center{
  text-align: center;
}

.wb-page-body-2{
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
}
.wb-page-body{
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.wb-page-body-content{
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* flex-grow:1;
  height: 100%; */
}

.btn.huge{
  font-size: 2rem;
}

.other-started-workbooks{
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.org-dashboard{
  padding: .8rem;
}

.org-dashboard .header{

}

.org-dashboard .body{
  
}

.org-dashboard .list-row{
  display: flex;
  flex-wrap: wrap;
}

.list-row .user-info{
  width:max(25vw, 350px);
}

.list-row .workbook-summary{
  width:max(25vw, 350px);
  border: 1px solid var(--color-mute-2);
  border-radius: var(--border-radius);
  padding:var(--border-padding);
  margin: var(--border-margin);
}
.list-row h4{
  margin: 0;
}

.search-bar{
  position: sticky;
  top:0;
  margin-bottom: .5rem;
  border-bottom: 1px solid var(--color-mute-2);
  background-color: var(--color-mute-3);
}

.copy{
  cursor: pointer;  
}

.copy:hover{
  text-decoration: underline;
}

.workbook-template-list{
  display: flex;
  flex-wrap: wrap;
}

.workbook-template-list .list-row{
  margin: .5rem;
  padding: .5rem;
  border: 1px solid var(--color-mute);
  border-radius: var(--border-radius);
}

.shared-with-list{
  max-height: 30vh;
  overflow: auto;
}

.shared-with-list .shared-with-info{
  border: 1px solid var(--color-mute);
  border-radius: var(--border-radius);
  margin: .5rem;
  padding: .5rem;
}

.bordered{
  border: 1px solid var(--color-mute);
  border-radius: var(--border-radius);
  margin: .5rem;
  padding: .5rem;
  position: relative;
}

.removed-banner{
  position: absolute;  
  top:0;
  left:0;
  right:0;
  bottom:0;
  font-size: 2.5rem;
  color: var(--color-mute-2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-info .click{
  margin-right: 1rem;
  margin-top: .5rem;
}

.stickey{
  position: sticky;
  top:0;
}

.basic-page-header{
  z-index: 100;
}

.basic-page-header h2{
  margin: 0;
  padding:0;
}

h1, h2, h3, h4{
  margin: 0;
}


.click {
  cursor: pointer;
}

.list-row{
  padding: .2rem .5rem;
}
.list-row:nth-child(even){
  background-color: var(--color-mute-2);
}
.list-row:nth-child(odd){
  background-color: var(--color-mute-3);
}

.requested-reviews.done{
  background-color: var(--color-mute);
  color:var(--color-mute-2);
  border-left:1rem solid var(--color3)
}