.workbook{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.workbook.preview{
}

.wb-page-body-2{
    margin: 1rem .2rem;
}

.wb-page{
    padding-left: .5rem;
    padding-right: .5rem;
}
.workbook .page-thumbnail-container {
    flex-grow: 1;
    display:flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: stretch;
    border-bottom: 1px solid #999;
}

.page-thumbnail-container .children{
    flex-grow: 1;
    display: flex;
}

.page-thumbnail-container .children{
    display: flex;
    align-items: center;
}

.workbook .wb-page-thumbnail {
    width:Min(calc(1140px / 4 - 3em), calc(100vw - 2em));
    height: 300px;
    border-radius: .3rem;
    border:1px solid #aaaaaa;
    margin: .5em;
    transition: box-shadow 1s ease-in-out;
    display: flex;
    flex-direction: column;    
    background-color: #ffffff;
    position: relative;
}



.workbook .wb-page-thumbnail.not-yet{
    border: 1px solid #444;
    box-shadow: inset 0 0 10rem #444;
    cursor: not-allowed;
}
.workbook .wb-page-thumbnail.preview.not-yet{
    cursor: pointer;
}
.workbook .wb-page-thumbnail.active.not-yet{
    box-shadow: inset 0 0 5rem #444, .1rem .1rem 2rem #375c94;
}

.workbook .wb-page-thumbnail .milestone{
    position:absolute;
    bottom:-1px; left:-1px; right:-1px;
    text-align: center;
    z-index: 15;
    background-color: #666;
    color:#ffffff;
    font-size: .8rem;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
}


.workbook .wb-page-thumbnail .milestone.ms2{
    background-color: rgba(55, 92, 148, 0.8);
}

/* .workbook .wb-page-thumbnail.preview .not-shown{ */
.preview .skip-not-show{
    position: absolute;
    top:0;
    bottom:0;
    right:-.7rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.preview .filter{
    position: absolute;
    top:0;
    left:.5rem;
    color:#ff0000;
    z-index: 10;
}
.preview .not-shown{
    position: absolute;
    z-index: 10;
    background-color: rgba(0,0,0,.4);
    text-align: center;
    left:0;
    right:0;
    color:#ffffff;
    }
    .workbook .wb-page-thumbnail .access-blocked{
    position: absolute;
    z-index: 10;
    background-color: rgba(0,0,0,.4);
    text-align: center;
    left:0;
    right:0;
    color:#ff0000;
    }

.wb-page-thumbnail:hover{
    box-shadow: .3rem .3rem .8rem rgba(0,0,0,0.15);
    transition: box-shadow 0.3s ease-in-out;
}

.wb-page-thumbnail .image{
}
.wb-page-thumbnail .image img{
    width: calc(100% + 2px);
    height: 125px;
    object-fit: cover;
    margin: -1px;    
    border-radius: .3rem .3rem 0 0;
}
.wb-page-thumbnail .description{
    flex-grow: 1;
    overflow: auto;    
    padding: .1rem .3rem;
    font-size: .9rem !important;
    color:#000000 !important;
}
.wb-page-thumbnail .stats{
    font-size: .6rem;  
    text-align: right;  
    padding-right: .5rem;
    position: relative;
    z-index: 20;
}
.wb-page-thumbnail h3{
    font-size: 1.1rem;
    overflow: hidden;
    height: 2.2rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    position: relative;
    margin-block-start:0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    line-height: 1em;
    margin:0;
}

.wb-page{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    position: relative;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    min-height: calc(100vh - 10rem);
}
.wb-element{    
    position: relative;
    min-height: 3rem;
}

.wb-group{
    min-height: 3rem;
}

.wb-element .image{
    display: flex;
    justify-content: center;    
}

.wb-element .embeded-video{
    display: flex;
    justify-content: center;
}

.wb-element .embeded-video iframe{
    height: min(calc(100vh - 20rem), calc(min(1000px, 100vw - 4rem) * .563));
}

.wb-element .image img{
    max-height: calc(100vh - 20rem);
    max-width: min(1000px, 100vw - 4rem);
}
.signer, .set-element{
    position: relative;
}
.wb-group{
    position: relative;    
}
.workbook .form-group{
    position: relative;
}

.workbook .header{
    position: sticky;
    top:0;
    z-index: 5;
}

.wb-page .wb-page-body{
    flex-grow: 1;
    /* overflow: auto;       */
    margin: 1rem;
    margin-bottom: 3rem;
}
.footer{
    border-top: 1px solid #999999;
    padding-top: .3rem;
    position: fixed;
    /* width:min(1140px, 100vw);     */
    width: min(1140px - 2rem, 100vw - 2rem);
    bottom:0;
    /* margin: 0 auto; */
    z-index: 5;
    padding-bottom: .3rem;
    background-color: #FDFDFD;
    /* margin-left: -.5rem; */
    

}

.wb-milestone{
    margin:1rem 0 1rem 5rem;
    border-left:.4rem solid #aaaaaa;
    padding-left: 1rem;
}

.wb-milestone.requested{
    border-left:.4rem solid #9099e0;
}

.wb-milestone.approved{
    border-left:.4rem solid #b1e6a1;
}

.workbook .edit-me{
    display: none;
    position: absolute;
    top:0;
    right:0;
    background-color: rgba(0,0,0,.4);
    color:#fff;
    border-radius: 99rem;
    padding: .3em;
    height: 2em;
    width: 2em;
    z-index: 15;
    font-size: .7em;
}
.workbook .edit-me:hover{
    box-shadow: .2rem .2rem .8rem #000;
}
.workbook.preview .edit-me{
    display: initial;
}

.workbook .wb-element .edit-me{
    right: initial;
    left:0;
    top: initial;
}
.workbook .wb-group .edit-me{
    right: initial;
    left:0;
    top:1.5rem;

}

.workbook .form-group .edit-me{
    right: initial;
    left:33%;
    top: initial;

}

body .cool__menu{
    z-index: 10;
}

body .cool__option{
    padding: 4px;
}

.preview-trail{
    position: relative;        
    font-size: .7rem;
    width: 100%;
    height: 50px;
    max-height: 100px;
    overflow-x: scroll;
    overflow-y: auto;
}
.preview-trail .page-crumb .name{
    text-align: center;
    border-right: 1px solid #777;
    padding: 2px 0;
}
.preview-trail .page-crumb .name.active{
    box-shadow: 2px 2px 5px #000;
    padding: 2px 2px;
}
.preview-trail .page-crumb{
    display: flex;
    align-items: center;
}
.preview-trail .page-crumb.active{
    border-bottom: 1px solid #555;
}

.request-sig-options .sig-option{
    margin:1em;
    padding:.5em 1em;
    border:1px solid #aaa;
    border-radius: var(--border-radius);
    width:153px;
    text-align: center;
    cursor: pointer; 
  
  }
  
  .request-sig-options .sig-option.selected{
    background-color: #375c94;
    color:#ffffff;
  }

  .comment-viewer-container{
    max-height: calc(80vh - 5rem);
    overflow: auto;
  }

  .message-container{
    border: 1px solid #aaaaaa;
    border-radius: .3rem;
    margin-bottom: .5rem;
    margin-top: 1.5rem;
    position: relative;
    padding:.8rem .5rem;
    white-space: pre-line;
  }

  .message-container .header{
    position: absolute;
    top:-1rem;
    font-size: .8rem;
    left: 0;
    right:0;
  }

  .owners .owner{
    font-size: 1.2rem;
    padding: .4rem 0;
  }

  .accept-invite{
    padding: 2rem 1rem;
  }

  .soft-require{
    color:#88f;
  }

  
  @keyframes shakeAndGrow {
    /* from {
        font-size: 1em;
    }
    to {
        font-size: 1.3em;
    } */
    0%   { transform: translate(1px, 1px) rotate(0deg); }
    10%  { transform: translate(-1px, -2px) rotate(-1deg); }
    20%  { transform: translate(-3px, 0px) rotate(1deg); }
    30%  { transform: translate(3px, 2px) rotate(0deg); }
    40%  { transform: translate(1px, -1px) rotate(1deg); }
    50%  { transform: translate(-1px, 2px) rotate(-1deg); }
    60%  { transform: translate(-3px, 1px) rotate(0deg); }
    70%  { transform: translate(3px, 1px) rotate(-1deg); }
    80%  { transform: translate(-1px, -1px) rotate(1deg); }
    90%  { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
  }

  .app-name-core:hover{
    animation: shakeAndGrow 1s;
    animation-iteration-count: infinite;
  }
  .app-name-core{
    animation: shakeAndGrow 1s;
    animation-iteration-count: 3;
    animation-delay: 3s;
    position: absolute;
    right:0;
    left:.3em;
    cursor: pointer;
    display: inline-block;
    text-align: center;
  }
  .app-name{
    position: relative;
    width: 5em;
    display: inline-block;
  }
  .app-name-missing {
    color: rgba(255, 255, 255,0);
    animation: appNameMissing 5s;
    animation-direction: alternate;
    animation-iteration-count: 4;
    left:0;
    display: inline-block;
  }

  .app-name-missing.back{
    position: absolute;
    right:0;
    left: unset;
    display: inline-block;
  }


  .workbook .header .title .small{
    color: var(--color-mute);
    
  }
  
  .workbook-logo{
    margin-right: 1rem;
    max-height: 50px;
    max-width: 120px;
  }

  .workbook.signing .header, .workbook.signing .header .btn-default{
    background-color: #375c94;
    color:#ffffff;
  }

  .workbook.signing .input-description{
    /* display: none; */
  }

  .faq:not(:first-child){
    margin-top: .25rem;
    padding-top: .25rem;
    border-top: 1px solid #aaaaaa;
  }

  .faq-question{
    font-size: 1.4rem;
  }

  .faq-answer{
    margin-left:1rem;
  }

  .done-mark-as-done, .done-mark-as-publish{

    margin: 2rem;
    border-left: 1px solid #aaaaaa;
    padding: 1rem;
    border-radius: .4rem;
  }

  .done-mark-as-done .btn-secondary, .done-mark-as-publish .btn-secondary{
    font-size: 2em;
    padding: .4em 2em;
  }

  .indent-0{
    margin-left: inherit;
  }
  
  .indent-1{
    margin-left: 2rem;
  }
  
  .indent-2{
    margin-left: 4rem;
  }
  
  .indent-3{
    margin-left: 6rem;
  }
  .indent-4{
    margin-left: 8rem;
  }