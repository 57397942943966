:root {
  --theme-color-1: #90afc5;
  --theme-color-2: #336b87;
  --theme-color-3: #2a3132;
  --theme-color-4: #763626;
  --theme-color-white: #ffffff;
  --theme-color-black: #000000;
  --theme-form-background-even: #f6f6f6;
}

body {
  background-color: var(--theme-color-1);
  color: var(--theme-color-black);
}

body .orkboo.container {
  background-color: var(--theme-color-white);
  box-shadow: 0px 4px 8px var(--theme-color-2);
  padding: 1rem;
}

/* HEADERS */
.header .logo {
  margin: 0.3rem;
}
body .orkboo.container .basic-page-header {
  margin: -1rem;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid var(--theme-color-3);
  background-color: var(--theme-color-white);
  box-shadow: 0 4px 4px -2px var(--theme-color-3);
}

.workbook .header .title h1 {
  font-size: 1.5rem;
}

.workbook .header {
  margin: -1rem;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid var(--theme-color-3);
  background-color: var(--theme-color-white);
  box-shadow: 0 4px 4px -2px var(--theme-color-3);
}


@media (min-width: 949px) {
  .header h2.click {
    width: min(500px, 90vw);
  }
}

@media (max-width: 899px) {
  .workbook .workbook-header {
    display: block;
  }
  .workbook .workbook-header .flex-between {
    justify-content: center;
  }
  .header-menu {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.workbook .footer {
  background-color: var(--theme-color-white);
}

.workbook .form-group {
  background-color: var(--theme-form-background-even);
  padding: 0.3rem 0;
  border-radius: 0.3rem;
}

.workbook-preview {
  background-color: var(--theme-color-1);
  border-color: var(--theme-color-1);
  color: var(--theme-color-white);
}
.workbook-preview.new {
  background-color: var(--theme-color-2);
  border-color: var(--theme-color-2);
}

.workbook-preview:hover {
  box-shadow: 0.4rem 0.4rem 1rem var(--theme-color-3);
}

.orkboo .btn-secondary {
  color: var(--theme-color-white);
  background-color: var(--theme-color-3);
  border-color: var(--theme-color-3);
}
.orkboo .btn-primary {
  color: var(--theme-color-white);
  background-color: var(--theme-color-4);
  border-color: var(--theme-color-4);
}

.wb-tile-edit {
  color: var(--theme-color-3);
}

.wb-tile-edit:hover {
  color: var(--theme-color-4);
}

.workbook .wb-page-thumbnail.active {
  border: 1px solid var(--theme-color-4);
  box-shadow: inset 0 0 1.2rem var(--theme-color-4);
}

.workbook .wb-page-thumbnail .milestone.ms3 {
  background-color: var(--theme-color-2);
}

.document {
  background-color: var(--theme-color-1);
  margin: 1em;
  padding: 1em 1.5em;
}

.document .term {
  font-size: 1.1em;
  font-weight: 500;
  color: var(--theme-color-4);
}

.document h1,
.document h2,
.document h3,
.document h4,
.document h5,
.document h6 {
  color: var(--theme-color-white);
}
