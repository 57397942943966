
.project-tile {
    height: 200px;
    width: 250px;
    border: 1px solid #aaa;
    border-radius: 8px;
    margin: 8px;
    padding:.5em;
    box-sizing: border-box;
    position: relative;
    text-align: center;
  }

  .new-project{
      border-color: rgb(118, 118, 231);
      color:rgb(118, 118, 231);
  }
  
  .project-tile:hover {
    cursor: pointer;
    box-shadow:  0 0.25rem 0.75rem rgb(0 0 0 / 20%);
  }
  
  .project-tile .project-title{
    text-align: center;  
  }
  
  .project-tile .project-nickname{
    color:rgb(251, 90, 90);
    position: absolute;
    font-size: 2em;
    bottom:2.5em;
    left:-.5em;
    transform: rotateZ(-39deg);
    max-width: 270px;
    overflow: hidden;
    max-height: 1.1em;
    line-height: 1em;  
    text-overflow: ellipsis;
    background-color: rgb(255 255 255 / 30%);
  } 
  
  
.page-tile {
    width: 209px;
    border: 1px solid rgba(0,0,0,0.1);
    margin: .4em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
  }
  
  .page-tile-name {
    padding: 4px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  
  .page-tile:hover {
    cursor: pointer;
    box-shadow:  0 0.25rem 0.75rem rgb(0 0 0 / 20%);
  }

  .page-tile img{
      object-fit:cover;
      height: 200px;
      width: 100%;
  }

  .progress-bar-color.c100{
    background-color: seagreen;
  }
  
  .progress-bar-color{
      background-color: steelblue;
      height: 2px;
      position: absolute;
      bottom: 0;
      border-radius: 2px;
  }
  .public-DraftStyleDefault-block {
    margin: .2rem 0;
  }
  .toolbar-class{
    padding: .3rem 0;
    border:none;
    border-bottom: 1px solid #dfdfdf;
    background-color: #f3f3f3;
    margin-bottom: 0;
    display: flex;
    align-items: middle;
  }
  
  
  /* .rdw-inline-wrapper, .rdw-text-align-wrapper, .rdw-list-wrapper{
    margin: 0;
  }'
  .rdw-block-wrapper {
    margin-bottom: 0;
  }
    */
  /* .rdw-editor-toolbar[aria-hidden="true"] { filter: blur(4px); } 
  .wysiwg-expanded .rdw-editor-toolbar[aria-hidden="true"] { filter: blur(0) } */

  .editor-class{
      border:1px solid #dfdfdf;      
      border:none;    
      min-height: 4rem;  
      max-height: 8rem;
  } */


  .editor-class.big{
      height: 30em;
      z-index: 15;
      position: relative;
  }
  .wrapper-class{
    margin-bottom: .5em;
    border:1px solid #dfdfdf;     
    border-radius: .3rem; 
    background-color: #ffffff;
  }

  .note-list{
      display: flex;
      flex-wrap: wrap;

  }

  .note-tile{
      margin: .3em;
      padding:.3em .6em;
      border-radius: .3em;
      cursor: pointer;
      background-color: #ddd;
  }

  .note-tile:hover{
      box-shadow: 2px 2px 5px #aaa;
  }

  
.bibliography-unit {
    border: 1px solid #aaa;
    border-radius: 4px;
    padding: 8px;
    display: flex;
    justify-content: space-between;    
    align-items: center;
    margin: .5em;
  }

  .bibliography-unit .nobreak{
  }

  .source-list{
  }

  .author-summary {
    display: flex;
    justify-content: space-between;
    display: inline-flex;
    background-color: #ccc;
    padding:.3em .6em;
    border-radius: .2em;
    margin:.3em;
  }

.menu-links li{
    list-style-type: none;
    font-size: 1.1em;
    margin-top: .3em;
}

.menu-links li.active{
    font-size: 1.1em;
    color:#000;
}

.menu-links li a {
    color:#222;
    cursor: pointer;
}

.menu-links li a:hover{
    text-decoration: none;
}

.menu-switch{
    position: fixed;
    right: .2em;
    top:.2em;
    font-size: 1.5em;
    background-color: rgba(0,0,0,0.1);
    padding: .2em;
    border-radius: .2em;
    z-index: 9;
    
}

.media-container iframe{
    width:100%;
    height: min(max(500px, 50vh), 100vh - 80px);
    margin: 2em 0;
}

.photo-container{
    display: flex;
    flex-wrap: wrap;
}

.image-container-tile{

}

.file-image{
    text-align: center;    
}

.file-image img{
    max-height: max(70vh, 100vh - 300px);
}

.file-comments{
    text-align: center;
    margin:.5em;
    min-height: 1em;
}

.file-controls{
    margin: 1em;
}

.file-controls .btn{
    margin: .4em;
}

embed.file-preview-grow{
    height: min(max(500px, 50vh), 100vh - 80px);
}

.pdf-thumbnail{
    text-align: center;
    font-size: 6em;

}

.isef-forms-wizard{
    margin-bottom: 4em;
}

.isef-question-list{
    padding: 1em 1.5em;
    margin:.5em;
    background-color: #eee;
    border-radius: .3em;
}
.isef-question-list .question{
    display: flex;
    align-items: baseline;
}

.isef-question-list .question label{
    margin-left: 1em;
}

.user-search-bar {
    display: flex;
    align-items: center;
    max-width: min(80%, 500px);
}

.user-search-bar label{
    margin: auto 1em;
}

.user-list{
    margin: 1em .5em;
    padding:1em .5em;
    background-color: #f3f3f3;
    border-radius: .3em;
}

.user-list .users-project-summary{
    border:1px solid #aaa;
    margin:.3em;
    padding:.2em .5em;
    border-radius: .2em;
}

.hasError{
    color:#b33;
    padding-left: 1em;
}

.release-notes-container {
    margin:.5em;
    padding:.5em;
}

.release-notes-container .release-note{
    background-color: #f4f4f4;
    padding:.5em 1em;
    border-radius: .25em;
    margin-bottom: 1em;
}

.release-notes-container .release-note .release-note-at{
    font-size: .8em;
}

.document .site-name{
    color:#444;
}

.document .name-short{
    color:#444;
}

