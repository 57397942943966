


.expandable-div{
    white-space: pre-wrap;
    word-wrap: break-word;
    margin-bottom: 1em;
    position: relative;
    max-height: 150px;
    overflow: hidden;
    width: 100%;
}

.expandable-div.open{
    overflow: auto;
    max-height: inherit;
    padding-bottom: 2em;

}
.expandable-div.open .bottom-gradient{
    height: 2em;
}
.bottom-gradient{
    position: absolute;
    background: linear-gradient(0deg,#fff,hsla(0,0%,100%,0));
    bottom: 0;right:0;
    width: 100%;
    height: 50%;
  }
  .bottom-gradient .more-button{
    position: absolute;
    bottom: 0; right:0;
    background: linear-gradient(90deg,hsla(0,0%,100%,0) 0,#fff 20%);
  }

  .expandable-img{
      height: 100%;
      text-align: center;
  }
  
  .expandable-img img{
    max-width: 100%;
  }

  .expandable-img.open{
      position: fixed;
      left:0;
      right:0;
      top:0;
      bottom: 0;
      background-color: rgba(0, 0, 0, .7);
      z-index: 1100;
      display: flex;
      justify-content: center;
      vertical-align: middle;
      height: 100%;
  }

  .expandable-img.open img{
      object-fit: contain !important;
      height: calc(100% - 30px)!important;
      margin-top: 15px;
      width: auto !important;
  }

  .expandable-img.open .close-btn{
      position: absolute;
      top: 0.1em;
      right:.1em;
      font-size: 2.5em;
      color:#dddddd;
      cursor: pointer;
      border-radius: 99em;
      padding:.3em;
  }

  .expandable-img.open .close-btn:hover{
      background-color: #000000;
      border: 1px solid #777777;
      color:#ffffff
  }

  .link-to-show{
      font-size: 1.1em;
      cursor: pointer;

  }

  .link-to-show.fill{
      display: block;
      position: absolute;
      top:0;
      right:0;
      left:0;
      bottom: 0;
      font-size: 2em;
      text-align: center;
      margin-top: 2.7em;
  }

  .link-to-show:hover{
      text-decoration: underline;
  }

  .easy-viewer-container{
      position: fixed;
      left:0;right:0;top:0;bottom:0;
      background-color: rgba(0, 0, 0, .7);
    z-index: 1100;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .easy-viewer{
      width: min(1200px, 100vw);
      position: relative;
      background-color:#fafafa;
      padding: .5em;
      display: flex;
      flex-direction: column;
      min-height: min(400px, 100vh);
      max-height: min(1200px, 100vh);
  }

  .easy-viewer .viewer-body{
      flex-grow: 1;
      overflow: auto;
      display: flex;
      justify-content: center;
  }

  .easy-viewer .viewer-body .easy-view-image-full{
    max-width: min(1100px, calc(100vw - 6rem));
    max-height: min(1100px, calc(100vh - 8rem));
  }
  .easy-viewer .viewer-header{
      display: flex;
      justify-content: space-between;
      font-size: 1.2em;
  }

  .easy-viewer .viewer-header .click{
      font-size: 1.7em;
  }

  .slick-image-group{
      overflow: hidden;
      position: relative;
      height: 100%;
      width: 100%;
      display: flex;
      flex-wrap: wrap; 
      justify-content: center;
  }


  .slick-image-group.no-2 img{
    width: 50%;
    height: 50%;
}
.slick-image-group.no-3 img{
    width: 33.33%;
    height: 50%;
}
.slick-image-group.no-4 img{
    width: 33.33%;
    height: 33.33%;
}
.slick-image-group.no-5 img{
    width: 20%;
    height: 33.33%;
}



.existing-file-container {
  max-height: min(500px, 40vh);
  overflow: auto;
}

.existing-file-container .existing-file-item{
    margin-bottom: .4em;
    padding:.3em;
    border-radius: 3px;
    border:1px solid #aaaaaa;
}
.bumper{
    padding:1rem;
}
.easy-file-image{
    display: flex;
    align-items: flex-start;
}

.easy-file-image .bumper{
    padding-top: 0;
}

.wysiwg-expanded {
    position: fixed;
    top:0;
    bottom:0;
    right:0;
    left:0;
    background-color: rgba(0, 0, 0, .7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
}

.wysiwg-expanded .expanded-hide,.expanded-show{
    display: none;
}



.wysiwg-expanded .expanded-show{
    display: inline-block;
}

.wysiwg-expanded .wysiwg-container{
    background-color: #ffffff;
    padding: 2em;
    border-radius: .4em;
    width: min(1400px, calc(100vw - 3em));
    height: min(920px, calc(100vh - 10em));
}

.wysiwg-expanded .editor-class.editor-short{
    min-height: calc(min(920px, calc(100vh - 10em)) - 9em);
    max-height: calc(min(920px, calc(100vh - 10em)) - 9em);
    overflow: auto;
}
/* .rdw-dropdown-optionwrapper{
    z-index: 90 !important;
    position: relative;
} */

.rdw-dropdown-wrapper[aria-expanded="true"]{
    z-index: 90 !important;
}
.rdw-dropdown-wrapper{
    z-index: 0 !important;
}
.wysiwg-expanded .editor-class{
    border:1px solid #aaa;
}

.grade-range-input{
    display: flex;
    flex-wrap: wrap;
  }
  
  .grade-range-input .grade{
    background-color: #666;
    min-width: 50px;
    height: 50px;
    padding: .3em;
    display: flex;
    justify-content: center;
    align-items: center;
    border:1px solid #888;
    color:#fdfdfd;
    flex-wrap: wrap;
  }
  
  .grade-range-input .grade.active{
    background-color: #333;
    padding: .2em;
    
  }
  
  .grade-range-input .grade:hover{
    text-decoration: underline;
    background-color: #555;
  }