
  @keyframes lookHere {
    from {
        color: rgba(0,0,0,.9);
    }
    to {
        color: rgba(0, 0, 0,.4);
    }
  }

  .look-here{
    position: absolute;
    z-index: 100;
    animation-name: lookHere;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    font-size: 80px;
  }

  .look-down{
    position: absolute;
  }

  .look-down .look-here{
    top:-90px;
    transform: rotate(90deg);
    animation-name: lookHereDown;
    animation-iteration-count: 1;
    animation-duration: 4s;
    animation-delay: 0s;
  }

  @keyframes appNameMissing {
    from {
        color: rgba(0,0,0,1);
    }
    to {
        color: rgba(255, 255, 255,0);
    }
  }

  @keyframes lookHereDown {
    
    from {
        color: rgba(0,0,0,0);
    }
    to {
        color: rgba(0,0,0,1);
    }
    0% {top: -350px;}
    3% {top: -100px;}
    10% {top: -325px;}
    13% {top: -100px;}
    20% {top: -300px;}
    23% {top: -100px;}
    30% {top: -275px;}
    33% {top: -100px;}
    40% {top: -250px;}
    43% {top: -100px;}
    50% {top: -225px;}
    53% {top: -100px;}
    60% {top: -200px;}
    63% {top: -100px;}
    70% {top: -175px;}
    73% {top: -100px;}
    80% {top: -150px;}
    83% {top: -100px;}
    90% {top: -125px;}
    93% {top: -100px;}
    100% {top: -100px;}
  }
