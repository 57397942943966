
.modal-not-bs {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.4);
  }
  
  .modal-content h3{
    font-size: 18px;
    margin: 0 !important;
    padding:0;
  }
  
  .modal-content {
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    /* display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center; */
    overflow-y: auto;
    max-height: 98vh;
    position: relative;
  }

  

.modal-open{
  overflow: hidden;
}
  
  .modal-content.modal-m {
    width:min(500px,100vw);
    max-width:min(500px,100vw);
  }
  
  .modal-content.modal-l {
    width:min(760px,100vw);
    max-width:min(760px,100vw);
  }
  
  .modal-content.modal-xl {
    width:min(1080px,100vw);
    max-width:min(1080px,100vw);
  }
  
  .modal-content.modal-xxl {  
    width:min(1200px,100vw);
    max-width:min(1200px,100vw);
  }
  .modal-content.modal-full {  
    width:100vw;
    max-width:100vw;
    height: calc(100vh - 30px);
  }
  
  .modal-body {
    overflow-y: auto;
    border-top: 1px solid #aaaaaa;
    min-height: 150px;
    min-width: 150px;
    max-height: calc(100vh - 10em);
    overflow-x: hidden;
  }
  
  .modal-full .modal-body{
    /* max-height: ; */
    max-height: calc(100vh - 5em);
  }

  .modal-close{
    position: absolute;
    right:.3em;
    top:.3em;
  }